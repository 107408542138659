var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preload" },
    [
      _c(
        "vs-row",
        { staticStyle: { height: "100vh" } },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "12"
              }
            },
            [
              _c("div", [
                _c("img", {
                  staticClass: "elementToFadeInAndOut",
                  staticStyle: { width: "100px" },
                  attrs: {
                    src: require("../../assets/images/logo/logo-somente-on.png")
                  }
                })
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }